import React, { Component } from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import { connect } from 'react-redux';


import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignInPage from '../SignIn';
import HomePage from '../Home';
import AccountPage from '../Account';
import TodayPage from '../Today';
import NotFoundPage from '../404'

import { fetchUser } from '../../actions/auth';

import * as ROUTES from '../../constants/routes';
import requireAuth from '../../config/requireAuth';

class App extends Component {

  componentWillMount() {
    this.props.fetchUser();
  }

  render() {
    return (
      <Router>
        <div className="container">
          <div id="navigation">
            <Navigation />
          </div>
          <div className="content">
          <Switch>
            <Route path={ROUTES.LANDING} component={LandingPage} />
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route exact path={ROUTES.TODAY} component={TodayPage} />
            <Route exact path={ROUTES.HOME} component={requireAuth(HomePage)} />
            <Route path={ROUTES.ACCOUNT} component={requireAuth(AccountPage)} />
            <Route component={NotFoundPage} />
          </Switch>
          </div>
        </div>
      </Router>
    )
  }
}

export default connect(null, { fetchUser })(App);