import React from 'react'
import { DateInput } from '@progress/kendo-react-dateinputs';
import Ticks from './Ticks';
import { editGoal, deleteGoal } from '../../actions/goals';
import { connect } from 'react-redux';

class GoalItem extends React.Component {
  constructor(props) {
    super(props);

    const { goal } = props;
    this.state = {
      expanded: false,
      edit: false,
      goalID: goal.goalID,
      title: goal.title,
      description: goal.description,
      dueDate: new Date(goal.dueDate.seconds * 1000),
      deleted: false
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.goal.goalID !== this.props.goal.goalID){
      this.setState({
        goalID: this.props.goalID,
        title: this.props.goal.title,
        description: this.props.goal.description,
        dueDate: new Date(this.props.goal.dueDate.seconds * 1000)    
      })
    }
  }

  submitDeleteGoal = (e) => {
    e.preventDefault();
    this.props.deleteGoal(this.props.goal.goalID);
    this.setState({
      edit: false,
      expanded: false,
      deleted: true
    })
  }

  toggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded,
      edit: false
    })
  }

  toggleEdit = () => {
    this.setState({
      edit: !this.state.edit,
      expanded: false
    })
  }

  submitEditGoal = e => {
    e.preventDefault();
    const { editGoal } = this.props;
    editGoal({
      goalID: this.props.goal.goalID,
      title: this.state.title,
      description: this.state.description,
      dueDate: this.state.dueDate
    });
    this.toggleEdit()
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });    
  }

  getActivity(){
    if(!this.state.expanded || this.state.edit){
      return null
    }
    
    return (
      <ul className="activity-list">
        <li className="goal-grid activity">
          <span className="grid-left">4/20</span>
          <div className='dot-and-description grid-right'>
            <div><img src='/img/dot.png' alt='bullet'/></div>
            <span>activity description</span>
          </div>
        </li>
      </ul>
    )
  }

  getActionButtons() {
    let editButton = null,
        expandButton = null;

    if(this.state.expanded || this.state.edit){
      editButton = 
        <button className="edit-button" onClick={this.toggleEdit}>
          <img  src={this.state.edit ? '/img/close.png' : '/img/edit.png'} alt='edit goal'></img>
        </button>
    }

    if(!this.state.edit){
      expandButton = 
        <button>
          <img onClick={this.toggleExpanded} className="expand-button" src={this.state.expanded ? '/img/collapse.png' : '/img/expand.png'} alt='expand icon'></img>
        </button>
    }

    return (
      <div className="buttons">
        {editButton}
        {expandButton}
      </div>
    )
  }

  getContent() {
    const {goal} = this.props
    const {ticks} = goal
    const dueDate = new Date(this.state.dueDate)
    let dueDateMonth = dueDate.getMonth()+1
    if(dueDateMonth<10){
      dueDateMonth="0"+dueDateMonth
    }
    let dueDateDate = dueDate.getDate()
    if(dueDateDate<10){
      dueDateDate="0"+dueDateDate
    }
    const dueDateString = dueDateMonth +"/"+ dueDateDate
    
    if(!this.state.edit){
      if(this.state.expanded){
        return (
          <div className="goal-grid multi">
            <div className="grid-left-top">{dueDateString}</div>
            <span className="grid-right-top title">{goal.title}</span>
            
            <span className="grid-right-bottom">{goal.description}</span>
          </div>
        )
      }
      return (
        <div className="goal-grid">
          <Ticks className="grid-left" goalID={goal.goalID} ticks={ticks}/>
          <div className="goal grid-right">
            <div className="title">
              <span>{goal.title}</span>
            </div>
          </div>
        </div>  
      )
    }
    return(
      <div className="editGoal">
        <div className="editGoal-left">
          <button className="commit" onClick={this.submitEditGoal}><img className="paper-airplane" src="/img/commit.png" alt="add goal" /></button>
          <button><img className="trashcan" src="/img/trashcan.png" alt="delete" onClick={this.submitDeleteGoal}/></button>
        </div>
        <div className="editGoal-right">
          <div className="edit-goal-title-input-div">
            <input 
              className="edit-goal-title"
              type="text"
              name="title"
              onChange={this.updateInput}
              value={this.state.title}
            />
            <label htmlFor="edit-goal-title">Title</label>
          </div>
          
          <div className="edit-goal-due-date-input-div">
            <DateInput className="date-input"
              value={dueDate}
              onChange={this.updateInput}
              name="dueDate"
            />
            <label htmlFor="edit-goal-due-date">End Date</label>
          </div>
          <div className="edit-goal-description-input-div">
            <input 
              className="edit-goal-description" 
              type="text" 
              name="description"
              onChange={this.updateInput}
              value={this.state.description}
            />
            <label htmlFor="edit-goal-description">Description (optional)</label>
          </div>
        </div>
      </div>
    )
  }

  
  render() {
    const {goal} = this.props
    return (
      <div key={goal.goalID} className={this.state.expanded ? 'goalListItem expanded' : 'goalListItem'}>
        {this.getContent()}
        {this.getActivity()}
        {this.getActionButtons()}
      </div>
    )
  }
}

export default connect(null, {deleteGoal, editGoal})(GoalItem);