import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as actions from '../../actions';
import { currentWorkspaceRef } from '../../config/firebase';
import './style.css';

import GoalItem from './GoalItem'

class GoalList extends Component {
  
  componentWillMount() {
    this.props.fetchGoalsByWorkspace(currentWorkspaceRef);
  }

  renderGoals() {
    const data = this.props.goals;    
    
    let date = new Date();
    let msPer90Days = 1000*86400*90
    let interval = date.getTime() + msPer90Days
    let goals090 = [], goals91plus = []

    _.map(data, (value) => {
      if (value.dueDate.seconds < interval/1000){
        goals090.push(value)
      }else{
        goals91plus.push(value)
      }
    })

    function goalMapper(value, key) {      
      return (
        <GoalItem key={key} goal={value}/>
      )
    }
    let jsx90 = _.map(goals090, goalMapper)
    let jsx90plus = _.map(goals91plus, goalMapper)

    return (
      <div className="goal-container">
        <h2>0-90 Days</h2>
        {jsx90}
        <h2>90+ Days</h2> 
        {jsx90plus}
      </div>
    )
  }

  render() {
    const { goals } = this.props;
    if(_.isEmpty(goals)) {   
      return (
        <div id="loading">Loading..</div>
      )};
    return (
        <div>{this.renderGoals()}</div>
    );
  }
}

const mapStateToProps = ({goals}) => {
  return {
    goals: goals.list
  };
};

export default connect(mapStateToProps, actions.goals)(GoalList);