import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { signIn } from '../../actions/auth';
import * as ROUTES from '../../constants/routes';
import PercentageBox from '../PercentageBox';

import './signin.css';

class SignInPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      now: new Date(),
      currentPercentage: 0
    };
  }
  static contextTypes = {
    router: PropTypes.object
  };

  componentWillUpdate(nextProps) {
    if( nextProps.loggedIn ) {
      this.context.router.history.push(ROUTES.HOME);
    }
  }
  componentDidMount(){
    document.title = 'Goals';
    document.getElementById('favicon-link').href = "/img/goals_favicon.png";
  }
  render() {
    return (
      <div id="signIn">
        <button onClick={this.props.signIn}>
          <h1>sign in</h1>
        </button>
        <PercentageBox now={this.state.now} />
      </div>
    )
  }
}

function mapStateToProps({ auth }) {  
  return { 
    loggedIn: auth.loggedIn 
  };
}

export default connect(mapStateToProps, { signIn })(SignInPage);